.NotValidate-Container {
    position: relative;
    display: flex;
    margin-left: 450px;
    margin-top: 70px;
    justify-content: center;
    align-items: center;
    width: 539px;
    height: 436px;
    background-color: white;
    box-shadow: 0px 1px 1px rgba(156, 156, 156, 0.15);
    border: 1px solid #e8e8e8;

    .NotValidate-Image {
        position: absolute;
        width: 143px;
        height: 131px;
        top: 65px;
    }
    .NotValidate-Title {
        position: absolute;
        display: flex;
        top: 215px;
    }
    .NotValidate-Text {
        width: 80%;
        margin-top: 150px;
        font-size: 18px;
    }
}
