@import "./custom-inputs";
@import "./buttons";
@import "./fonts";
@import "./forms";

.planeo-workforce {
    p,
    span {
        padding: 0;
        margin: 0;
    }

    hr {
        margin: 0;
        padding: 0;
        border: 1px solid #707070;
        opacity: 0.1;
    }

    span.link {
        color: #379af4;
        cursor: pointer;
    }

    span.highlight {
        color: #379af4;
        font-weight: bold;
    }

    .input-icon {
        position: relative;

        .icon {
            position: absolute;
            bottom: 25px;
            right: 0.5rem;
            z-index: 1000;
            align-content: center;
            cursor: pointer;
            font-size: 1rem;
            color: #707070;
        }
    }

    .modal-dialog-centered {
        display: flex;
        justify-content: center;
    }

    @include include-buttons();
    @include include-fonts();
    @include include-forms();
    @include include-custom-inputs();
}
