@import "../../../Styles/Commons";
@import "../../../Styles/layout-bp";

@mixin nav-icon-layout($size: 25px, $color: #546166) {
    svg {
        font-size: $size;
        color: $color;
        cursor: pointer;

        &:hover {
            color: #000000;
        }
    }
}

.planeo-dashboard-navbar {
    width: 100%;
    position: relative;

    .navbar-container {
        width: 100%;
        padding: 10px 30px;
        gap: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #ffffff;

        @include layout-bp(lt-sm) {
            flex-direction: row-reverse;
            padding: 10px;
            justify-content: space-between;
        }

        .md-menu {
            @include nav-icon-layout();
        }

        .navbar-brand {
            cursor: pointer;
            img {
                width: auto;
                height: 30px;
            }
        }

        .navbar-profile {
            position: relative;
            display: flex;
            align-items: center;
            gap: 5px;
            cursor: pointer;
            margin-left: auto;

            span {
                font-size: 16px;
                font-weight: bold;
                color: #333333;

                max-width: 200px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .md-keyboard-arrow-down {
                @include nav-icon-layout();
            }

            @include layout-bp(lt-sm) {
                margin-left: unset;

                span {
                    display: none;
                }
            }
        }
    }
}

.pagination .page-item .page-link {
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
  

.pagination.pagination-sm .page-item .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
}