@mixin include-forms {
    form {
        font-size: 12px;

        input {
            border: 1px solid #0000001f;
            font-size: 15px;

            &.validation-error {
                border-color: #d60000;
            }
        }

        input[type="checkbox"] + label {
            position: relative;
        }

        .form-label {
            text-align: left;
        }

        .form-check {
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .form-text {
            color: #d60000;
            font-size: 12px;
            display: inline-block;
        }
    }
}
