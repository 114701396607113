@mixin include-custom-inputs {
    .input-search {
        .input-group-text {
            height: 100%;
            border-radius: 4px 0 0 4px;
            background-color: #ffffff;
            border-right: 0px;
            padding-left: 10px;
        }

        input {
            font-size: 13px;
            border-left: 0px;
        }
    }
}
