.firebase-emulator-warning {
    width: max-content !important;
}

@mixin transition($property, $time) {
    transition: $property $time ease-in-out;
}

$Primary-Purple-Color: #343ba7;
$Primary-Link-Color: #379af4;

.dashboard-page-container {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
    margin-left: 15px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
}

.full-page-container {
    display: flex;
    justify-content: center;

    .NotValidate-Container {
        margin: 0;
        margin-top: 5rem;
        padding: 3rem;
        text-align: center;
    }

    .NotValidate-Container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .not-validate-Image {
        width: 90px;
    }

    .not-validate-Text {
        font-size: 14px;
    }

    .not-validate-Title {
        font-size: 30px;
    }
}
