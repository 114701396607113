.custom-modal{
    width: 100%;
    height: 70vh;
    padding: 0;
    --bs-modal-width: 90%;
    --bs-modal-height: 100vh;
}

.custom-modal2 {
    width: 50%;
    height: 70vh;
    padding: 0;
    --bs-modal-width: 50%;
}

.modal-header {
    height: 7vh !important;
    min-height: 100%;
    border-radius: 0;
}

.custom-modal2 .modal-body {
    height: 68vh !important;
    min-height: 100%;
    border-radius: 0;
}

.modal-footer {
    height: 8vh;
    min-height: 100%;
    border-radius: 0;
}