.file-uploader-info-list {

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: #404040;
    font-size: 14px;
    padding: 1rem 0rem;
    width: 100%;
    gap: 1rem;
    max-height: 100px;
    overflow-y: auto;

    .file-card {
        width: calc(50% - 0.5rem);
        display: flex;
        gap: 1rem;
        padding: 0 0.5rem;

        img {
            height: auto;
        }

        .file-card-content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            white-space: nowrap;
            overflow-x: hidden;
            line-height: 14px;

            .file-name {
                font-size: 14px;
                font-weight: bold;
            }

            .file-size {
                font-size: 11px;
                color: #868686;
            }
        }

        .close-button {
            cursor: pointer;
            font-size: 1rem;
            color: #868686;
        }
    }

    .text-error {
        color: red;
        font-size: 11px;
    }
}