.main-logo {
    width: 100%;
    margin-bottom: 10px;
  }
  
.tituloLogin {
    top: '321px';
    left: '131px';
    width: '485px';
    height: '130px';
    text-align: 'center';
    font-size: '15px';
    letter-spacing: 0px;
    color: '#FFFFFF';
    opacity: 1;
    margin-bottom: 30px;
}

.btn-outline-primary, .btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary:visited {
    border-color: #343BA7 !important;
    color: #343BA7 !important;
}

.btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary:visited {
    background-color: #343BA7 !important;
    color: #FFF !important;
}

.input-text{
    position: relative;
}

.fa-eye-slash{
    position: absolute;
    right: 10px;
    top: 7px;
    font-size:20px;
    cursor:pointer
}

.fa-eye{
    position: absolute;
    right: 10px;
    top: 7px;
    font-size:20px;
    cursor:pointer
}

.btn-purple, .btn-outline-primary:active, .btn-outline-primary:visited {
    border-color: #343BA7 !important;
    background-color: #343BA7 !important;
    color: '#FFF'
}

.btn-info, .btn-outline-info:active, .btn-outline-info:visited {
    border-color: #FFF !important;
    background-color: transparent !important;
    color: #FFF !important
}

.g-recaptcha {
    transform-origin:left top;
    display: inline-block;
}