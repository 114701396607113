@import "../../../../Styles/layout-bp";

.planeo-page-container {
    width: 100vw;
    height: 100vh;
}

.planeo-signup-page {
    width: 100%;
    height: 100%;
    background-color: #ffffff;

    display: grid;
    grid-template: 1fr / 450px 1fr;

    @include layout-bp(lt-md) {
        grid-template-columns: 300px 1fr;
    }

    @include layout-bp(lt-sm) {
        grid-template: auto 1fr / 1fr;
    }

    .banner-section {
        background-color: red;
        height: 100%;
    }

    .signup-form-ssection {
        background-color: green;
        height: 100%;
    }
}
