#planeo-login-form {
    width: 304px;
    font-size: 12px;
    display: flex;
    flex-direction: column;

    .captcha {
        width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: center;
    }

    .lineal-form-control {
        display: flex;
        justify-content: space-between;
    }

    button {
        width: 100%;
    }
}
