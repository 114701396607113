@import '../../../../Styles/layout-bp.scss';

.navbar-submenuNotificacion {
    position: relative;
    min-width: 350px;
    min-height: 500px;
    max-height: 500px;
    max-width: 350px;
    background: white;
    box-shadow: 5px 10px 30px 15px #00000029;
    z-index: 2;
    margin-left: auto;
    color: #333333a6;
    margin-right: -158px;
    font-size: 15px;
    margin-top: -5px;
    padding-bottom: 50px;
    border-radius: 10px;

    @include layout-bp(lt-sm) {
        right: unset;
        left: 5px;
    }
}

.navbar-submenuNotificacionlista {
    height: 465px !important;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.navbar-submenuNotificacionlista::-webkit-scrollbar {
    display: none;
}

.modal-triangle {
    position: absolute;
    top: -8px;
    right: 166px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 8px solid white;
}

.spinner-container {
    margin-top: 60px !important;
}

.spinner {
    height: 50px;
    width: 50px;
}

.fixed-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    padding: 5px;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    height: 35px;
    box-shadow: 0 -2px 5px rgba(0,0,0,0.1);
}

.custom-modal3 {
    width: 100%;
    height: 80vh;
    padding: 0;
    --bs-modal-width: 80%;
}

.modal-header {
    height: 7vh !important;
    min-height: 100%;
    border-radius: 0;
}

.custom-modal3 .modal-body {
    height: 72vh !important;
    min-height: 100%;
    border-radius: 0;
}

.modal-footer {
    height: 12vh !important;
    border-radius: 0;
}

.btn-outline-danger {
    background-color: transparent !important;
    color: #dc3545 !important;
    border: 1px solid #dc3545 !important;
    font-weight: bold;

    &:hover {
        background-color: #dc3545 !important;
        color: white !important;
    }
}

.btn-outline-success {
    background-color: transparent !important;
    color: green !important;
    border: 1px solid green !important;
    font-weight: bold;

    &:hover {
        background-color: green !important;
        color: white !important;
    }
}

.close ::before{
    content: 'X';
    visibility: visible;
    color: "black";
    font-weight: bold;
}

.sr-only::before{
    visibility: hidden;
}

.close{
    visibility: hidden;
    position: absolute;
    right: 10px;
    top: 15px;
    width: 35px;
    height: 35px;
    background-color: rgb(247, 12, 12, 0.5);
}

.document-preview{
    height: 0;
}

.frame {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    transform-origin: 40 0;
    object-fit: cover;
    object-position: bottom;
}

.spinner-container2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    .spinner2 {
        font-size: 15px;
        height: 40px;
        width: 40px;
        margin-top: -50px;
        color: #343ba7;
    }
}