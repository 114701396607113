@mixin include-buttons {
    button.btn {
        font-size: 13px;
        padding: 10px 40px;
        border-radius: 4px;
        background-color: #343ba7;
        border: 1px solid #343ba7;
        color: white;
        height: fit-content;
        gap: 10px;
        align-items: center;

        &.btn-icon {
            display: flex;
            span {
                height: 1rem;
            }
            svg {
                font-size: 20px;
            }
        }

        &.btn-small {
            padding: 5px 9px;
        }

        &.btn-basic {
            border: none;
            background-color: transparent;
            color: #343ba7;
        }

        &.btn-outline {
            background-color: transparent;
            color: #343ba7;
            font-weight: bold;

            &:hover {
                background-color: #343ba7;
                color: white;
            }
        }

        &.btn-outline-contrast {
            border-color: #ffffff;
            color: white;

            &:hover {
                background-color: #ffffff22;
            }
        }
    }
}