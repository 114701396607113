.file-uploader-component {

    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 14px;
    

    p {
        margin: 0;
        padding: 0;
    }

    .file-uploader-dropzone-container {
        display: flex;
        background: #fcfcfc 0% 0% no-repeat padding-box;
        border: 1px dashed #abb0b7;
        padding: 1rem 2rem;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        border-radius: 5px;
    
        letter-spacing: 0.1px;
        white-space: pre-wrap;
    }
    
    .file-uploader-info-container {
    
        width: 100%;
    }
}