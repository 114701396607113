@import "./../../../Styles/layout-bp";

.planeo-not-found-page {
    width: 100vw;
    height: 100vh;

    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 5rem;
    padding: 45px;
    z-index: 1;

    .background-image {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: -1;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .page-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5rem;

        .error-number {
            font-size: 235px;
            font-weight: bold;
            line-height: 200px;

            @include layout-bp(lt-md) {
                font-size: 150px;
                line-height: 150px;
            }

            @include layout-bp(lt-sm) {
                font-size: 100px;
                line-height: 100px;
            }
        }

        .error-message {
            font-size: 21px;
        }

        .btn {
            background: transparent;
            font-weight: 13px;
            border-color: white;
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }

    .logo-footer {
        width: 105px;
        img {
            width: 100%;
        }
    }
}
