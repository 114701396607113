.modal-alert-message {
    text-align: center;
    .modal-content {
        padding: 45px 30px;

        .modal-body {
            display: flex;
            flex-direction: column;
            gap: 26px;

            .title {
                font-size: 20px;
            }

            p {
                font-size: 15px;
            }

            button {
                padding: 10px 50px;
                font-weight: lighter;
            }
        }
    }
}
