#planeo-dashboard-sidemenu {
    display: flex;
    height: 100%;
    background: #233c46;
    overflow-y: auto;

    &.hidden {
        width: 0;
    }

    .sidemenu-content {
        margin-top: auto;
        margin-bottom: auto;

        ul {
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 0;
            list-style-type: none;
        }

        .sidemenu-item {
            display: flex;
            padding: 12px 23px;
            gap: 12px;
            align-items: center;
            cursor: pointer;

            span {
                color: #a7b1b5;
                font-size: 14px;
                font-weight: bold;
            }

            &.active {
                background: adjust-color($color: #379af4, $alpha: -0.8);
            }

            &:hover {
                background: adjust-color($color: #000000, $alpha: -0.8);
            }
        }
    }
}
