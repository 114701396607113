.spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    .spinner {
        font-size: 15px;
        height: 100px;
        width: 100px;
        color: #343ba7;
        margin: 0px auto;
    }
}
