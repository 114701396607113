@import "./../../../../../Styles/layout-bp";

.planeo-signup-page-banner {
    padding: 80px 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 25px;

    @include layout-bp(lt-sm) {
        padding: 30px;
    }

    background-size: cover;
    background-repeat: no-repeat;

    font-size: 13px;
    color: white;

    .main-logo {
        width: 238px;
        margin-bottom: 10px;
    }
}
