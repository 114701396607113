@import "../../../../../../Styles/layout-bp";

#planeo-login-modal {
    .modal-content {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 43px 63px;

        @include layout-bp(lt-sm) {
            padding: 43px 20px;
        }

        .main-logo {
            height: 60px;
            margin-bottom: 50px;

            img {
                height: 100%;
                width: auto;
            }
        }

        .modal-footer {
            font-size: 12px;
            color: #707070;
            display: flex;
            flex-direction: column;
            text-align: center;
            margin-top: 16px;
            border-top: 0;
        }
    }
}
