#planeo-alert-dialog {
    max-width: 450px;

    .modal-content {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 43px 30px;
        align-items: center;
        gap: 30px;

        img {
            width: 130px;
        }

        .title {
            color: #3b3d46;
            font-weight: bolder;
            font-size: 20px;
            padding-bottom: 10px;
        }

        .message {
            color: #707070;
            font-size: 15px;
            margin-top: 15px;
        }

        .button-box {
            display: flex;
            justify-content: space-evenly;
            width: 100%;
        }
    }
}
