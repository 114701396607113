@import "../../../Styles/layout-bp.scss";

.navbar-submenu {
    position: absolute;
    min-width: 300px;
    background: white;
    border-radius: 4px;
    box-shadow: 0px 0px 6px #00000029;
    z-index: 2;
    right: 30px;
    color: #333333a6;
    font-size: 15px;

    @include layout-bp(lt-sm) {
        right: unset;
        left: 5px;
    }

    menu {
        display: flex;
        flex-direction: column;

        .button {
            cursor: pointer;
            &:hover {
                font-weight: bold;
            }
        }
    }

    &__section {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px;

        img {
            width: 15px;
        }
    }

    &__info {
        display: flex;
        flex-direction: row;

        .company-data {
            display: flex;
            flex-direction: column;

            .company-name {
                font-size: 20px;
                font-weight: bold;
                color: #333333;
            }

            .account-config {
                font-weight: bold;
                cursor: pointer;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }

    .divider {
        width: 100%;
        height: 1px;
        background: #e8eaed;
    }
}
