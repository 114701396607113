@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
    margin: 0;
    padding: 0;
    font-family: "Nunito", sans-serif !important;
}

@import "./Commons.scss";
#root {
    height: 100%;
    overflow-x: hidden;
    --clr-button-primary: #343ba7;
}

html {
    height: 100%;
}
body {
    margin: 0;
    overflow-x: hidden;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: normal;
    line-height: 1.6;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#lightBlueBckground {
    background: "#F6F9FE" !important;
    color: white !important;
}

.scrolbar {
    overflow: scroll;
}
.scrolbar::-webkit-scrollbar {
    width: 8;
    height: 8;
}

.scrolbar-primary::-webkit-scrollbar {
    scrollbar-color: #f5f5f5;
}
/*Buttons*/
.Out-line-button {
    color: rgb(77, 83, 178) !important;
    border-color: var(--clr-button-primary) !important;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
    width: 100%;
    transition: all 0.3s ease-in-out;
}

.Out-line-button:hover {
    color: white !important;
    border-color: $Primary-Purple-Color !important;
    background-color: $Primary-Purple-Color !important;
}
.Out-line-button-white {
    color: white !important;
    border-color: white !important;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
    width: 100%;
    transition: all 0.3s ease-in-out;
}

.Out-line-button-white:hover {
    color: var(--clr-button-primary) !important;
    border-color: white !important;
    background-color: white !important;
}

.Button-Primary {
    color: white !important;
    border-color: $Primary-Purple-Color !important;
    background-color: $Primary-Purple-Color !important;
}
.Button-Primary:hover {
    color: white !important;
    border-color: $Primary-Purple-Color !important;
    background-color: rgb(44, 48, 104) !important;
}
input[type="checkbox"] {
    border-color: #707070;
    height: 20px;
    width: 20px;

    flood-color: var(--clr-button-primary);
}

input[type="checkbox"]:checked {
    background-color: $Primary-Purple-Color;
    border-color: $Primary-Purple-Color;

    height: 20px;
    width: 20px;

    flood-color: var(--clr-button-primary);
}
.Spinner-Container {
    background-color: $Primary-Purple-Color;
    width: 100%;
    height: 100%;
    text-align: center;
    border-radius: 5px;
    color: white;
}
.Spinner-Container .spinner-border {
    margin: 5px;
}
