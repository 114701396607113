@import "../../../../../../Styles/layout-bp";

#planeo-password-recovery-modal {
    &.modal-dialog {
        max-width: 450px;
    }

    .main-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 140px;

        img {
            width: min(250px, 80%);
        }
    }

    .recovery-form {
        padding: 38px 63px 30px 63px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        @include layout-bp(lt-sm) {
            padding-left: 20px;
            padding-right: 20px;
        }

        .title {
            width: 100%;
            color: #343ba7;
            font-weight: bold;
        }

        .buttons-group {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }
}
