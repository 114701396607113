#planeo-habeas-data-modal {
    font-size: 16px;
    color: #333333de;

    .modal-title {
        font-size: 21px;
        font-weight: bold;
        color: #000000de;
    }

    .modal-body {
        padding: 18px 35px;

        span {
            color: #343ba7;
            font-weight: bold;
        }

        p {
            margin-top: 10px;
        }
    }
}
