#planeo-dashboard {
    width: 100vw;
    height: 100vh;

    .planeo-dashboard-overlap {
        width: 100%;
        height: 100%;
        background: transparent;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }

    .planeo-dashboard-navbar {
        position: fixed;
        z-index: 600;
    }

    .planeo-dashboard-main-content {
        display: grid;
        grid-template: 1fr / auto 1fr;

        width: 100%;
        height: 100%;
        padding-top: 62px;
    }
}
