.file-uploader-info-short {
    color: #404040;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .title {
        font-size: 18px;
        padding-bottom: 1rem;
    }

    .info-span {
        display: flex;
        gap: 1rem;
        margin-left: 1rem;

        .icon {
            width: 24px;
            height: 24px;
        }
    }

    .download-link {
        text-decoration: underline;
        color: #379af4;
        cursor: pointer;
    }
}
