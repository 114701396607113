@import "./../../../../../Styles/layout-bp";

.planeo-signup-page-form {
    font-size: 13px;
    padding: 60px 88px 60px 115px;
    overflow: auto;

    display: flex;
    flex-direction: column;
    gap: 25px;

    @include layout-bp(lt-md) {
        padding-left: 80px;
        padding-right: 80px;
    }

    @include layout-bp(lt-sm) {
        padding-left: 50px;
        padding-right: 50px;
        overflow: unset;
    }

    form {
        max-width: max(750px, 80%);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
        row-gap: 15px;

        @include layout-bp(lt-sm) {
            column-gap: 10px;
            row-gap: 5px;
        }

        @include layout-bp(lt-md) {
            .form-group {
                grid-column: 1 / 3;
            }
        }

        .company-document-field {
            display: grid;
            grid-template-columns: 7fr 2fr;
            column-gap: 14px;
        }

        .submit-field {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            gap: 20px;

            @include layout-bp(lt-md) {
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }

        .field-fullwidth {
            grid-column: 1 / 3;
        }
    }
}
