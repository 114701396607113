@mixin include-fonts {
    .text-centered {
        text-align: center;
    }

    @for $i from 1 through 30 {
        .font-#{$i} {
            font-size: #{$i}px;
        }
    }

    .font-black {
        color: black;
    }
}
