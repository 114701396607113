.planeo-alert-toast-component {
    position: absolute;
    left: 0px;
    width: 100%;
    color: white;
    background-color: #41a3f3;
    text-align: center;
    align-items: center;
    z-index: 500;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 0.5rem 2rem;

    .alert-message {
        flex-grow: 1;
    }

    &.success {
        background-color: #5abe2b;
    }

    &.info {
        background-color: #41a3f3;
    }

    &.error {
        background-color: #ec6666;
    }

    &.warn {
        background-color: #f7c654;
    }

    .close-button {
        font-size: 1.5rem;
        cursor: pointer;
    }
}
